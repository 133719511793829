import { Environment } from "./EnviormentEnum";

export const ENV = Environment.PRODUCTION;

const DEV_API_URL = 'https://localhost:4430';
const PROD_API_URL = 'https://data.statistics.green-innovation.bg:4430';

const DEV_HUB_URL = 'https://localhost:4431';
const PROD_HUB_URL = 'https://data.statistics.green-innovation.bg:4431';

export const API_URL = ENV === Environment.DEVELOP ? DEV_API_URL : PROD_API_URL;
export const HUB_URL = ENV === Environment.DEVELOP ? DEV_HUB_URL : PROD_HUB_URL;